/** @jsx jsx */
/** @jsxFrag */
import { jsx, css } from '@emotion/react';
import React, { Ref } from 'react';

import * as AIUtilities from '../game/AIUtilities';

import GameTimeView from './GameTimeView';
import Symbols, {
  BaseResourceToSymbol,
  CardSubtypeToSymbol,
  CardTypeToSymbol,
  ResourceToSymbol,
  SYMBOL_TO_IMAGE,
  SymbolView,
  TokenToSymbol,
} from './Symbols';
import { InflatedGame, InflatedPlayer } from '../game/Game';
import {
  CardSubType,
  CardType,
  isCardSubType,
  isCardType,
} from '../game/CardTypes';
import {
  computeBaseCounters,
  makeContext,
  makeContextFromGame,
} from '../game/Rules';
import { match } from 'ts-pattern';
import invariant from 'invariant';
import { Resource, Token, TokenToDisplayName } from '../game/Resources';
import { AnimationRefSetter, PlayerAnimationRefs } from './AnimationHooks';

type User = any;

export type PlayerSummaryCountMode = 'counters' | 'total';
interface PlayerSummaryViewProps {
  player: InflatedPlayer;
  game: InflatedGame;
  user: User;
  readyByUserID: { [k: string]: boolean };
  isWinning: boolean;

  countMode: PlayerSummaryCountMode;
  toggleCountMode: () => void;

  animationRefSetter?: AnimationRefSetter;
}
export default class PlayerSummaryView extends React.Component<PlayerSummaryViewProps> {
  render() {
    let { player, game, user, readyByUserID, isWinning, animationRefSetter } =
      this.props;

    const context = makeContextFromGame(player, game);
    const production_counts = AIUtilities.totalProductionCounters(context);

    const card_counts = AIUtilities.cardCompositionCounts(player);
    const base_counts = computeBaseCounters(context);

    let is_ready = !!readyByUserID[player.userID];
    let game_running = !game.gameEndTimestamp;
    let split_start_timestamp =
      !is_ready && game_running ? game.phaseStartTimestamp : undefined;
    let extra_split_time = game_running
      ? this.props.game.phaseThinkingMillisByPlayerID[player.userID]
      : this.props.game.totalThinkingMillisByPlayerID[player.userID];
    let extra_pre_split_time = game_running
      ? this.props.game.totalThinkingMillisByPlayerID[player.userID]
      : undefined;

    const cards = ORDERED_CARD_TYPES.map((type) => {
      return (
        <CardCountView key={type} type={type} count={card_counts[type] || 0} />
      );
    });
    const subtype_cards = ORDERED_CARD_SUBTYPES.map((subtype) => {
      return (
        <CardCountView
          key={subtype}
          type={subtype}
          count={card_counts[subtype] || 0}
        />
      );
    });
    let card_counts_view = (
      <div>
        <div>{cards}</div>
        <div>{subtype_cards}</div>
      </div>
    );

    let container_style: React.CSSProperties = {
      backgroundColor: isWinning ? 'rgb(255, 242, 204)' : 'rgb(247, 247, 247)',
    };

    let ready_style = {
      display: 'inline-block',
      width: 9,
      backgroundColor: is_ready ? 'green' : 'red',
      height: '100%',
    };

    let profile_picture = (
      <img
        src={user.profilePictureURL}
        css={PlayerSummaryStyles.profileSymbol}
      />
    );

    let reroll_symbol =
      player.counters.rerollTokens > 0 ? (
        <SymbolView
          symbol={Symbols.REROLL}
          css={PlayerSummaryStyles.rerollSymbol}
          title={'Reroll Available'}
        />
      ) : null;
    return (
      <div
        ref={(node) => {
          animationRefSetter?.(
            player.userID,
            PlayerAnimationRefs.summary,
            node,
          );
        }}
        css={PlayerSummaryStyles.container}
        style={container_style}
      >
        <div style={ready_style} />
        <div css={PlayerSummaryStyles.topRow}>
          <div css={PlayerSummaryStyles.verticalStackItem}>
            <div css={PlayerSummaryStyles.inlineRow}>
              {profile_picture}
              <span css={PlayerSummaryStyles.name}>{user.name}</span>
            </div>
            <GameTimeView
              css={PlayerSummaryStyles.time}
              splitStartTimestamp={split_start_timestamp}
              extraSplitTime={extra_split_time}
              extraPreSplitTime={extra_pre_split_time}
            />
          </div>
          <div css={PlayerSummaryStyles.verticalStackItem}>
            <div css={PlayerSummaryStyles.resourceWrapper}>
              <PlayerFavorDisplay
                ref={(node) =>
                  animationRefSetter?.(
                    player.userID,
                    PlayerAnimationRefs.favor,
                    node,
                  )
                }
                count={player.counters.favor}
              />
              {game.options.warTokens && (
                <PlayerTokenDisplay
                  token={Token.War}
                  count={player.counters.warTokens}
                />
              )}

              <div css={PlayerSummaryStyles.productionWrapper}>
                <PlayerResourceCounter
                  resource="gold"
                  tooltip="Gold"
                  count={
                    this.props.countMode === 'counters'
                      ? player.counters.gold
                      : player.counters.gold + base_counts.gold
                  }
                  generateCount={production_counts.gold}
                  baseCount={base_counts.gold}
                  style={{ marginBottom: 4 }}
                  countMode={this.props.countMode}
                  toggleCountMode={this.props.toggleCountMode}
                  ref={(node) =>
                    animationRefSetter?.(
                      player.userID,
                      PlayerAnimationRefs.gold,
                      node,
                    )
                  }
                />
                <div css={PlayerSummaryStyles.militaryAndRerollRow}>
                  {reroll_symbol}
                  <PlayerResourceCounter
                    resource="military"
                    tooltip="Military"
                    count={
                      this.props.countMode === 'counters'
                        ? player.counters.military
                        : player.counters.military + base_counts.military
                    }
                    generateCount={production_counts.military}
                    baseCount={base_counts.military}
                    countMode={this.props.countMode}
                    toggleCountMode={this.props.toggleCountMode}
                    ref={(node) =>
                      animationRefSetter?.(
                        player.userID,
                        PlayerAnimationRefs.military,
                        node,
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div css={PlayerSummaryStyles.cardCountsContainer}>
              {card_counts_view}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ORDERED_CARD_TYPES = [
  CardType.Resource,
  CardType.Conflict,
  CardType.Prayer,
  CardType.Basic,
];
const ORDERED_CARD_SUBTYPES = [
  CardSubType.Unit,
  CardSubType.Building,
  CardSubType.Event,
];

const PlayerSummaryStyles = {
  container: css({
    display: 'flex',
    height: 65,
    padding: 3,
    marginTop: 5,
    marginBottom: 2,
  }),
  topRow: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  verticalStack: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-center',
  }),
  verticalStackItem: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
  inlineRow: css({
    display: 'inline-flex',
  }),
  resourceWrapper: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '65%',
    paddingLeft: 4,
  }),
  productionWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  }),
  name: css({
    display: 'inline-block',
    fontSize: 20,
    fontWeight: 'bold',
    maxWidth: 180,
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  card: css({
    position: 'relative',
    boxSizing: 'border-box',
    display: 'inline-flex',
    borderRadius: 5,
    color: 'white',
    height: 22,
    width: 17,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 2,
    marginBottom: 2,
    fontSize: 12,
  }),
  cardCountUnderlay: css({
    position: 'absolute',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '90%',
    width: '90%',
  }),
  cardCountText: css({
    zIndex: 1,
  }),
  cardCountsContainer: css({
    display: 'flex',
    justifyContent: 'space-around',
  }),
  time: css({
    color: 'rgba(0, 0, 0, 0.4)',
  }),
  profileSymbol: css({
    marginLeft: 5,
    marginRight: 5,
    height: 16,
    width: 16,
  }),
  favorSymbol: css({
    height: 23,
    width: 23,
    marginBottom: 2,
    marginRight: 4,
  }),
  favorContainer: css({
    display: 'flex',
    fontSize: 23,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  tokenSymbol: css({
    height: 15,
    width: 15,
  }),
  rerollSymbol: css({
    height: 15,
    width: 15,
    marginRight: 4,
  }),
  tokenContainer: css({
    display: 'flex',
    fontSize: 17,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  militaryAndRerollRow: css({
    display: 'flex',
    flexDirection: 'row',
  }),
} as const;

const CARD_TYPE_TO_COLOR = {
  [CardType.Basic]: 'rgb(139, 87, 43)',
  [CardType.Resource]: 'rgb(68, 120, 19)',
  [CardType.Conflict]: 'rgb(212, 52, 34)',
  [CardType.Prayer]: 'rgb(69, 148, 226)',
  [CardType.Leader]: '',
};
const CARD_TYPE_TO_SYMBOL_FILTER = {
  [CardType.Basic]:
    'invert(14%) sepia(37%) saturate(3619%) hue-rotate(12deg) brightness(96%) contrast(98%)',
  [CardType.Resource]:
    'invert(12%) sepia(58%) saturate(1310%) hue-rotate(56deg) brightness(97%) contrast(103%)',
  [CardType.Conflict]:
    'invert(11%) sepia(42%) saturate(4855%) hue-rotate(349deg) brightness(91%) contrast(115%)',
  [CardType.Prayer]:
    'invert(19%) sepia(41%) saturate(3232%) hue-rotate(198deg) brightness(93%) contrast(97%)',
  [CardType.Leader]: '',
};
const CardCountView = (props: {
  type: CardType | CardSubType;
  count: number;
}) => {
  const { type, count } = props;
  const { filter, opacity, symbol, backgroundColor } = match(type)
    .when(
      (x) => isCardType(x),
      (x) => {
        return {
          symbol: CardTypeToSymbol[x],
          opacity: 1,
          filter: CARD_TYPE_TO_SYMBOL_FILTER[x],
          backgroundColor: CARD_TYPE_TO_COLOR[x],
        };
      },
    )
    .when(
      (x) => isCardSubType(x),
      (x) => {
        return {
          symbol: CardSubtypeToSymbol[x],
          opacity: 0.62,
          filter: undefined,
          backgroundColor: 'grey',
        };
      },
    )
    .otherwise((x) => invariant(false, 'unknown card type/subtype: %s', x));
  const container_style: React.CSSProperties = {
    backgroundColor,
  };
  const icon_style: React.CSSProperties = {
    backgroundImage: `url(${SYMBOL_TO_IMAGE[symbol]})`,
    filter,
    opacity,
  };
  return (
    <div
      css={PlayerSummaryStyles.card}
      title={props.type as string}
      style={container_style}
    >
      <div css={PlayerSummaryStyles.cardCountUnderlay} style={icon_style} />
      <div css={PlayerSummaryStyles.cardCountText}>{count}</div>
    </div>
  );
};

export const PlayerFavorDisplay = React.forwardRef<
  HTMLDivElement,
  { count: number }
>((props, ref) => {
  return (
    <div title="Favor" css={PlayerSummaryStyles.favorContainer} ref={ref}>
      <SymbolView
        symbol={Symbols.FAVOR}
        css={PlayerSummaryStyles.favorSymbol}
      />
      <span>{props.count}</span>
    </div>
  );
});

export const PlayerTokenDisplay = React.forwardRef<
  HTMLDivElement,
  { token: Token; count: number }
>((props, ref) => {
  return (
    <div
      title={TokenToDisplayName[props.token]}
      css={PlayerSummaryStyles.tokenContainer}
      ref={ref}
    >
      <SymbolView
        symbol={TokenToSymbol[props.token]}
        css={PlayerSummaryStyles.tokenSymbol}
      />
      <span>{props.count}</span>
    </div>
  );
});

type PlayerResourceCounterProps = {
  resource: Resource;
  tooltip: string;
  count: number;
  generateCount: number;
  baseCount: number;
  countMode: PlayerSummaryCountMode;
  toggleCountMode: () => void;
  className?: string;
  style?: React.CSSProperties;
};
export const PlayerResourceCounter = React.forwardRef<
  HTMLDivElement,
  PlayerResourceCounterProps
>((props, ref) => {
  return (
    <div
      ref={ref}
      className={props.className}
      css={PlayerResourceStyles.container}
      style={props.style}
      onClick={props.toggleCountMode}
    >
      <SymbolView
        symbol={ResourceToSymbol[props.resource]}
        css={PlayerResourceStyles.symbol}
        title={props.tooltip}
      />
      {props.countMode === 'total' && (
        <SymbolView
          symbol={BaseResourceToSymbol[props.resource]!}
          css={PlayerResourceStyles.symbolBase}
        />
      )}
      <span css={PlayerResourceStyles.countText} title="Counters">
        {props.count}
      </span>
      <SymbolView
        symbol={BaseResourceToSymbol[props.resource]!}
        css={[PlayerResourceStyles.symbolSmall]}
        title="Base"
      />
      <span
        css={
          props.countMode === 'counters'
            ? PlayerResourceStyles.baseText
            : PlayerResourceStyles.baseTextLight
        }
        title="Base"
      >
        {props.baseCount}
      </span>
      <SymbolView
        symbol={Symbols.EFFECT_PRODUCTION}
        css={PlayerResourceStyles.symbolSmall}
        title="Production"
      />
      <span css={PlayerResourceStyles.generateText} title="Production">
        {props.generateCount}
      </span>
    </div>
  );
});

const PlayerResourceStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  }),
  symbol: css({
    minWidth: 15,
    minHeight: 15,
    width: 15,
    height: 15,
    marginRight: 2,
  }),
  symbolSmall: css({
    minWidth: 12,
    minHeight: 12,
    width: 12,
    height: 12,
    marginRight: 2,
    // filter: 'grayscale(1) brightness(0)',
    // opacity: 0.7,
    filter: 'saturate(0%)',
  }),
  symbolBase: css({
    position: 'absolute',
    height: 12,
    width: 12,
    top: 6,
    left: 8,
  }),
  countContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
  countText: css({
    minWidth: 20,
    textAlign: 'right',
    marginRight: 2,
  }),
  baseText: css({
    minWidth: 17,
  }),
  baseTextLight: css({
    minWidth: 17,
    color: 'rgba(0, 0, 0, 0.5)',
  }),
  generateText: css({
    minWidth: 17,
  }),
};
